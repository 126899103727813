import axios from '@/plugins/axios'

/**
 * Get advertiser
 */
const getCompany = async (id) => {
  try {
    const response = await axios.get(`admin/companies/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get companies
 */
const getCompanies = async ({
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  agencies_type = false,
  // eslint-disable-next-line camelcase
  advertisers_type = false
}) => {
  try {
    const params = {
      page: page
    }
    if (search) params.search = search
    // eslint-disable-next-line camelcase
    if (agencies_type) params.agencies_type = 1
    // eslint-disable-next-line camelcase
    if (advertisers_type) params.advertisers_type = 1

    const response = await axios.get('admin/companies', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update company
 */
const updateCompany = async (id, {
  name = null,
  // eslint-disable-next-line camelcase
  fantasy_name = null,
  // eslint-disable-next-line camelcase
  company_document = null,
  address = null,
  logo = null,
  country = null
}) => {
  try {
    const form = new FormData()
    form.append('country', country)
    form.append('name', name)
    form.append('fantasy_name', fantasy_name)
    form.append('company_document', company_document)
    form.append('address[zipcode]', address.zipcode)
    form.append('address[street]', address.street)
    form.append('address[number]', address.number)
    form.append('address[complement]', address.complement ?? '')
    form.append('address[neighborhood]', address.neighborhood)
    form.append('address[city]', address.city)
    form.append('address[state]', address.state)

    form.append('_method', 'PUT')
    const response = await axios.post(`admin/companies/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCompany,
  getCompanies,
  updateCompany
}
